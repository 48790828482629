<template>
  <span
      class="inline-flex items-center h-32 px-3
       py-3 transition duration-100
        focus:border-transparent text-base ease-in-out
        rounded
        disabled:cursor-not-allowed
        text-black border-none focus:ring-black"
      :class="[customClass]">
    {{ text }}
  </span>
</template>

<script>
import BaseError from "@/utils/validation/components/BaseError";
import themeConfig from "@/themeConfig";

export default {
  name: 'FakeTextField',
  components: {BaseError},
  props: {
    customClass: String,
    text: String
  },
  data() {
    return {

    }
  },
  watch: {

  },
  methods: {

  },


}
</script>
